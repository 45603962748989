import { React, useState } from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons"


function Navbar(props) {
    const [Click, setClick] = useState(false);
    const [Scroll, SetScroll] = useState(false);

    const Change_color = () => {
        if (window.scrollY >= 10) {
            SetScroll(true);
        }
        else { SetScroll(false); }
    };
    window.addEventListener('scroll', Change_color);


    return (
        <div className="">
            <nav className={Scroll ? " px-4 transition ease-out duration-200 text-black  delay-100 flex justify-between lg:justify-around w-full fixed top-0 left-0 right-0 z-10 " :
                "flex text-black lg:justify-around rounded-full bg-none lg:w-screen fixed px-4 lg:px-8 top-0 left-0 right-0 z-10"}>
                <div className= {`${Scroll? "bg-white/70 text-black" : "bg-none text-white"} lg:mt-6 mt-3 rounded-full flex w-screen lg:w-3/4 lg:py-[2px] justify-around`}>
                    <div className="flex items-center cursor-pointer group">
                        <img src={Logo1} alt="#Logo" className= {Scroll? " w-14 md:w-20 group-hover:opacity-70": "w-14 md:w-20 group-hover:opacity-70"} />
                    </div>
                    <div className={Scroll ? "lg:flex text-md hidden items-center space-x-20 text-xl capitalize" :
                        "lg:flex text-md hidden items-center space-x-20 text-xl capitalize"}>
                        <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                            Home
                        </a>
                        <a href="/Services" onClick={() => props.handleTabClick("Services")} className={`${props.isActive("Services")} flex hover:text-main-color transition ease-in-out cursor-pointer delay-150 duration-200`}>
                            Services
                        </a>
                        <a href="/Gallery" onClick={() => props.handleTabClick("Gallery")} className={`${props.isActive("Gallery")} flex hover:text-main-color transition ease-in-out cursor-pointer delay-150 duration-200`} >
                            Gallery
                        </a>
                    </div>
            
                    <div className={Scroll ? "lg:hidden text-3xl cursor-pointer flex items-center space-x-4" :
                        "lg:hidden space-x-4 text-3xl cursor-pointer flex items-center"}>
                        <div onClick={() => setClick(!Click)} className={Scroll ? "lg:hidden transition text-3xl cursor-pointer flex items-center text-main-color space-x-4" : "lg:hidden space-x-4 blkock text-3xl cursor-pointer flex items-center text-main-color"}>
                            {!Click ?
                                <FontAwesomeIcon className=" text-main2" icon={faBarsStaggered} /> :
                                <FontAwesomeIcon className=" text-main2" icon={faXmark} />
                            }
                        </div>
                    </div>
                    <div className={Scroll ? "hidden lg:flex items-center space-x-12 text-black" :
                        "hidden lg:flex items-center space-x-12 text-white"}>
                        <button
                            onClick={() => {
                                window.location.href = 'tel:6696236728';
                            }}
                            className= {Scroll? "transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color border-[1px] group cursor-pointer px-4 py-3 capitalize":"hover:bg-main-color hover:opacity-60 duration-200 ease-in-out delay-100 bg-main-color group cursor-pointer text-white px-4 py-3 capitalize"}>
                            <span className = {Scroll?"group-hover:text-white transition ease-in-out duration-200 tracking-wider uppercase":"tracking-wider uppercase"}>Contact Us</span>
                        </button>
                    </div>
                </div>
            </nav>
            {Click ?
                <nav className="overflow-hidden space-y-12 md:space-y-24 top-20 md:top-28 fixed p-6 w-screen bg-white transition duration-700 ease-out lg:hidden left-0 right-0 z-20  mx-auto  text-black">
                    <div className=" space-y-16 md:space-y-24 flex uppercase flex-col text-lg mt-12">
                        <a onClick={() => setClick(!Click)} className="" href="/">Home</a>
                        <a onClick={() => setClick(!Click)} className="" href="/Services">Services</a>
                        <a onClick={() => setClick(!Click)} className="" href="/Contact">Gallery</a>
                        <button
                            className="hover:bg-black group  transition ease-out duration-200 cursor-pointer px-4 py-3 border-black border-2 capitalize tracking-wide">
                            <a target="_blank" href="tel:6696236728" className="group-hover:text-black">Contact Us</a>
                        </button>
                    </div>
                </nav>
                : null
            }
        </div>
    );
};


export default Navbar;