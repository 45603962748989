import React , {useEffect} from "react";
import {Carousel , initTE} from "tw-elements"

function CarouselHome (){
    useEffect (() => {
        initTE({Carousel});
    },[]);
    
    return (
        <>
            <div id="CarouselHome" className="relative flex shadow-lg" data-te-carousel-init data-te-ride="carousel">
                
                <div className="relative w-screen overflow-hidden after:clear-both after:block after:content-['']">
                    <div className="relative float-left w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item data-te-carousel-active>
                        <div className="w-screen h-screen bg-bg2 img-test  relative bg-center bg-no-repeat bg-cover">
                            <div className="flex items-end h-screen w-screen justify-center tracking-wide text-white">
                                <div className="mb-12 space-y-2 lg:space-y-4 text-center flex flex-col items-center">
                                    <div className="space-x-2 flex font-Osward">
                                        <h2 className="text-5xl lg:text-8xl text-main-color">JN</h2>
                                        <h2 className="text-5xl lg:text-8xl text-main-color">Beauty Salon</h2>
                                    </div>
                                    <div className="flex flex-col font-extralight space-y-1 lg:text-xl lg:text-md italic font-Monterast">
                                        <h3 className=" lg:block hidden">2095 N Capitol Ave, Ste A , San Jose, CA 95132</h3>
                                        <h3 className="lg:hidden">2095 N Capitol Ave, Ste A</h3>
                                        <h3 className=" lg:hidden">San Jose, CA 95132</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="w-screen img-test h-screen bg-bg1 relative bg-center bg-no-repeat bg-cover">
                            <div className="flex items-end h-screen w-screen justify-center tracking-wide text-white">
                                <div className=" !font-serif mb-8 flex flex-col justify-center items-center space-y-8 text-center italic ">
                                    <div className=" w-3/5">
                                        <h2 className="text-3xl lg:text-5xl text-white ">Trusted Nail Care & Beauty Salons</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-fade data-te-carousel-item>
                        <div className="w-screen h-screen img-test bg-bg3 relative bg-center bg-no-repeat bg-cover ">
                            <div className="flex items-end h-screen w-screen justify-center tracking-wide text-white">
                                <div className=" !font-serif mb-8 flex flex-col justify-center items-center space-y-4 lg:space-y-6 text-center italic ">
                                    <div className="lg:w-3/5 w-4/5">
                                        <h2 className="text-3xl lg:text-5xl text-white ">Premier Nail Salon & Beauty Experts</h2>
                                    </div>
                                    <button
                                        className= "bg-main-color rounded-full hover:bg-gray-300/40 ease-in-out delay-150 duration-200 group cursor-pointer w-[250px] py-3 capitalize">
                                        <a href="tel:6696236728" className = "group-hover:text-white tracking-wider uppercase">Book Now</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#CarouselHome" data-te-slide="prev">
                    <span className="inline-block h-8 w-8">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </span>
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
                </button>
                <button className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#CarouselHome" data-te-slide="next">
                    <span className="inline-block h-8 w-8">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-6 w-6">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </span>
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
                </button>
            </div>
        </>
    );
};
export default CarouselHome;